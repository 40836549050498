import React, { useState, useEffect, useRef } from "react";
import { API_URL } from "../config";
import CreatePerforming from "./CreatePerforming";
import "../styles/TopPerforming.css";

const TopPerforming = ({ csrfToken }) => {
  const [topPerforming, setTopPerforming] = useState([]);
  const [filteredPerforming, setFilteredPerforming] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showCreatePerforming, setShowCreatePerforming] = useState(false);
  const [loading, setLoading] = useState(true);
  const createPerformingRef = useRef(null);

  // Fetch Top Performing
  useEffect(() => {
    const fetchTopPerforming = async () => {
      try {
        const token = localStorage.getItem("access_token");
        if (!token || !csrfToken) {
          console.error("Missing required tokens: Authorization or CSRF.");
          return;
        }

        const response = await fetch(`${API_URL}/users/my-top-performing/`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "X-CSRFToken": csrfToken,
          },
          credentials: "include",
        });

        if (response.ok) {
          const data = await response.json();
          setTopPerforming(data);
          setFilteredPerforming(data); // Initialize filteredPerforming
        } else {
          console.error(
            "Failed to fetch top performing:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error fetching top performing:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTopPerforming();
  }, [csrfToken]);

  const handleCreatePerforming = () => {
    setShowCreatePerforming((prevState) => !prevState);
    if (!showCreatePerforming && createPerformingRef.current) {
      createPerformingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handlePerformingAdded = (newPerforming) => {
    setTopPerforming((prevPerforming) => [...prevPerforming, newPerforming]);
    setFilteredPerforming((prevPerforming) => [...prevPerforming, newPerforming]);
    setShowCreatePerforming(false);
  };

  const handleDeletePerforming = async (performingId) => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token || !csrfToken) {
        console.error("Missing required tokens: Authorization or CSRF.");
        return;
      }

      const response = await fetch(`${API_URL}/users/delete-performing/${performingId}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
        },
        credentials: "include",
      });

      if (response.ok) {
        setTopPerforming((prevPerforming) =>
          prevPerforming.filter((performing) => performing.id !== performingId)
        );
        setFilteredPerforming((prevPerforming) =>
          prevPerforming.filter((performing) => performing.id !== performingId)
        );
        alert("Top performing deleted successfully.");
      } else {
        console.error(
          "Failed to delete performing:",
          response.status,
          response.statusText
        );
        alert("Failed to delete performing.");
      }
    } catch (error) {
      console.error("Error deleting performing:", error);
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = topPerforming.filter((performing) =>
      performing.performing_name.toLowerCase().includes(term)
    );
    setFilteredPerforming(filtered);
  };

  const formatDateTime = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit" };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  if (loading) {
    return <p>Loading top performing...</p>;
  }

  return (
    <div className="performing-list-container">
      <h2>My Brand Top Performing</h2>

      {/* Search Input */}
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by top performing name..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
      </div>

      {/* Performing Table */}
      <div className="performing-table">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Top Performing Name</th>
              <th>Description</th>
              <th>Tag</th>
              <th>Date Added</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredPerforming.length > 0 ? (
              filteredPerforming.map((performing, index) => (
                <tr key={performing.id}>
                  <td>{index + 1}</td>
                  <td>{performing.performing_name}</td>
                  <td>{performing.performing_description}</td>
                  <td>{performing.performing_tag}</td>
                  <td>{formatDateTime(performing.date_added)}</td>
                  <td>
                    <button
                      className="delete-button"
                      onClick={() => handleDeletePerforming(performing.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="no-top-performing-message">
                  No top performing found. Start by adding a new one!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Add Performing Button */}
      <button
        className="add-performing-button"
        onClick={handleCreatePerforming}
      >
        {showCreatePerforming ? "Cancel" : "Add New Top Performing"}
      </button>

      {/* Create Performing Form */}
      {showCreatePerforming && (
        <div ref={createPerformingRef}>
          <CreatePerforming
            csrfToken={csrfToken}
            onPerformingAdded={handlePerformingAdded}
          />
        </div>
      )}
    </div>
  );
};

export default TopPerforming;
