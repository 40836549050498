import React, { useState, useEffect } from 'react';
import '../styles/Chat.css';
import { API_URL } from '../config';

const Chat = ({ csrfToken, onClose }) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [metricsChoices, setMetricsChoices] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState('');

  useEffect(() => {
    const fetchMetricsChoices = async () => {
      try {
        const token = localStorage.getItem('access_token');
        if (!token || !csrfToken) {
          console.error('Missing required tokens: Authorization or CSRF.');
          return;
        }

        const response = await fetch(`${API_URL}/marketing_content/api/metrics-choices/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-CSRFToken': csrfToken,
          },
          credentials: 'include',
        });
        const data = await response.json();
        console.log('API response for metricsChoices:', data);
        if (Array.isArray(data)) {
          setMetricsChoices(data);
        } else {
          console.error('Unexpected response:', data);
          setMetricsChoices([]);
        }
      } catch (error) {
        console.error('Error fetching metrics choices:', error);
      }
    };

    fetchMetricsChoices();
  }, [csrfToken]);

  const handleSendMessage = async () => {
    if (!userInput.trim()) return;

    const updatedMessages = [...chatMessages, { role: 'user', content: userInput }];
    setChatMessages(updatedMessages);

    setIsLoading(true);
    try {
      const token = localStorage.getItem('access_token');
      if (!token || !csrfToken) {
        setChatMessages([...updatedMessages, { role: 'assistant', content: 'Authentication tokens are missing.' }]);
        setIsLoading(false);
        return;
      }

      const response = await fetch(`${API_URL}/chat/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
          'Authorization': `Bearer ${token}`,
        },
        credentials: 'include',
        body: JSON.stringify({ message: userInput }),
      });

      const data = await response.json();
      if (data.message) {
        setChatMessages([...updatedMessages, { role: 'assistant', content: JSON.stringify(data.message, null, 2) }]);
      } else if (data.error) {
        setChatMessages([...updatedMessages, { role: 'assistant', content: data.error }]);
      }
    } catch (error) {
      setChatMessages([...updatedMessages, { role: 'assistant', content: 'Error sending message. Please try again.' }]);
    } finally {
      setIsLoading(false);
    }

    setUserInput('');
  };

  const handleMetricSelection = async () => {
    if (!selectedMetric) return;

    setIsLoading(true);
    try {
      const token = localStorage.getItem('access_token');

      if (!token) {
        setUserInput('Authentication token missing.');
        return;
      }

      const response = await fetch(`${API_URL}/user-profile-metric/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ metric: selectedMetric }),
      });

      if (!response.ok) {
        const text = await response.text();
        console.error('Error response text:', text);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.content) {
        setUserInput(`Metric Data: ${JSON.stringify(data.content, null, 2)}`);
      } else {
        setUserInput('No data found for the selected metric.');
      }
    } catch (error) {
      console.error('Error fetching metric data:', error);
      setUserInput('Error fetching metric data.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
      <div className="full-screen-chat-container">
      <div className="chat-header">
        <h3>Chat</h3>
        <button onClick={onClose}>Close</button>
      </div>
      <div className="chat-messages">
        {chatMessages.map((msg, index) => (
            <div className="chat-message-box">
              <pre style={{whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>
                {msg.content.replace(/\\n/g, '\n')}
              </pre>
            </div>


        ))}
        {isLoading && <div className="loading">Loading...</div>}
      </div>
        <div className="chat-metric-selection">
        {/*  <select*/}
        {/*      value={selectedMetric}*/}
        {/*  onChange={(e) => setSelectedMetric(e.target.value)}*/}
        {/*>*/}
        {/*  <option value="">Select a Metric</option>*/}
        {/*  {metricsChoices.map((choice) => (*/}
        {/*    <option key={choice.value} value={choice.value}>*/}
        {/*      {choice.label}*/}
        {/*    </option>*/}
        {/*  ))}*/}
        {/*</select>*/}
        {/*<button onClick={handleMetricSelection} disabled={!selectedMetric || isLoading}>*/}
        {/*  Send Metric Data*/}
        {/*</button>*/}
      </div>
      <div className="chat-input">
        <textarea
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Type your message..."
          rows={5} // Adjust the height of the textarea
        />
        <button onClick={handleSendMessage} disabled={isLoading}>
          {isLoading ? 'Sending...' : 'Send'}
        </button>
      </div>
      </div>
  );
};

export default Chat;
