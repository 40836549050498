import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import '../styles/ProfileSettings.css';

const CompanySettings = ({ data }) => {
  const [companyData, setCompanyData] = useState({
    company_name: data?.company_name || '',
    company_url: data?.company_url || '',
    company_industry: data?.company_industry || '',
    company_size: data?.company_size || '',
  });
  const [saveMessage, setSaveMessage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({ ...companyData, [name]: value });
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('access_token');
      await axios.put(
        `${API_URL}/users/company-settings/`,
        companyData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setSaveMessage('Company information updated successfully!');
    } catch (error) {
      setSaveMessage('Failed to save changes. Please try again.');
    }
  };

  return (
    <div className="profile-settings-container">
      <h2 className="profile-settings-header">Company Edit</h2>
      <div className="profile-settings-section">
        <div className="profile-field">
          <label htmlFor="company_name">Company Name</label>
          <input
            type="text"
            id="company_name"
            name="company_name"
            value={companyData.company_name}
            onChange={handleInputChange}
          />
        </div>
        <div className="profile-field">
          <label htmlFor="company_url">Company URL</label>
          <input
            type="url"
            id="company_url"
            name="company_url"
            value={companyData.company_url}
            onChange={handleInputChange}
          />
        </div>
        <div className="profile-field">
          <label htmlFor="company_industry">Company Industry</label>
          <select
            id="company_industry"
            name="company_industry"
            value={companyData.company_industry}
            onChange={handleInputChange}
          >
            <option value="">Select your business sector</option>
            <option value="Technology">Technology</option>
            <option value="Finance">Finance</option>
            <option value="Healthcare">Healthcare</option>
            <option value="Retail">Retail</option>
            <option value="Education">Education</option>
            <option value="Manufacturing">Manufacturing</option>
            <option value="Real Estate">Real Estate</option>
            <option value="Transportation">Transportation</option>
            <option value="Energy">Energy</option>
            <option value="Entertainment">Entertainment</option>
            <option value="Hospitality">Hospitality</option>
            <option value="Construction">Construction</option>
            <option value="Legal">Legal</option>
            <option value="Agriculture">Agriculture</option>
            <option value="Telecommunications">Telecommunications</option>
            <option value="Automotive">Automotive</option>
            <option value="Food & Beverage">Food & Beverage</option>
            <option value="Aerospace">Aerospace</option>
            <option value="Public Sector">Public Sector</option>
            <option value="Nonprofit">Nonprofit</option>
            <option value="Media">Media</option>
            <option value="Pharmaceuticals">Pharmaceuticals</option>
            <option value="Insurance">Insurance</option>
            <option value="Sports">Sports</option>
            <option value="Consulting">Consulting</option>
          </select>
        </div>
        <div className="profile-field">
          <label htmlFor="company_size">Company Size</label>
          <select
            id="company_size"
            name="company_size"
            value={companyData.company_size}
            onChange={handleInputChange}
          >
            <option value="">Select organization size</option>
            <option value="Small">Small</option>
            <option value="Medium">Medium</option>
            <option value="Large">Large</option>
          </select>
        </div>
      </div>
      <button className="save-button" onClick={handleSave}>
        Save Changes
      </button>
      {saveMessage && <p className="save-message">{saveMessage}</p>}
    </div>
  );
};

export default CompanySettings;
