import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import '../styles/CompetitorsAnalysis.css';

const CompetitorsAnalysis = ({ onFetchDataComplete, csrfToken }) => {
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [mySite, setMySite] = useState('');
  const [competitors, setCompetitors] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [analysisData, setAnalysisData] = useState(null);

  const handleFetchData = async () => {
    setLoading(true);
    setError(null);

    const token = localStorage.getItem('access_token');
    console.log("Access Token:", token);
    console.log("CSRF Token:", csrfToken);

    if (!token || !csrfToken) {
      setError('Missing authentication tokens. Please log in again.');
      setLoading(false);
      return;
    }

    try {
      const payload = new URLSearchParams();
      payload.append('description', description);
      payload.append('location', location);
      payload.append('my_site', mySite);
      payload.append('competitors', competitors);

      const competitorsResponse = await axios.post(
        `${API_URL}/marketing_research/api/competitors-analysis/`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-CSRFToken': csrfToken,
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      const fetchedData = competitorsResponse.data;
      console.log("Fetched Data:", fetchedData);

      setAnalysisData(fetchedData.analysisCompetitorData || []);
      onFetchDataComplete(fetchedData);
    } catch (error) {
      console.error('Error fetching competitors analysis:', error);
      setError('Failed to fetch competitors analysis. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="competitors-analysis-container">
      <h1>Competitors Analysis</h1>
      {error && <p className="error-message">{error}</p>}
      <textarea
        className="input-field"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Description"
      />
      <input
        className="input-field"
        value={location}
        onChange={(e) => setLocation(e.target.value)}
        placeholder="Location"
      />
      <input
        className="input-field"
        value={mySite}
        onChange={(e) => setMySite(e.target.value)}
        placeholder="My Site"
      />
      <input
        className="input-field"
        value={competitors}
        onChange={(e) => setCompetitors(e.target.value)}
        placeholder="Competitors"
      />
      <button className="fetch-button" onClick={handleFetchData} disabled={loading}>
        {loading ? 'Fetching...' : 'Fetch Competitors Analysis'}
      </button>

      {analysisData && (
        <div className="analysis-data">
          <h2>Competitors Analysis Data</h2>
          <ul>
            {analysisData.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CompetitorsAnalysis;
