import React, { useState, useEffect } from "react";
import "../styles/TargetAudience.css";
import ReactSlider from "react-slider";
import { API_URL } from "../config";

function TargetAudience({ csrfToken }) {
  const [formData, setFormData] = useState({
    company_url: "",
    target_audience_name: "",
    target_audience_gender: "",
    target_audience_age_min: 10,
    target_audience_age_max: 65,
    target_audience_interests: "",
    target_audience_pain_points: "",
  });

  const [interests, setInterests] = useState([]);
  const [painPoints, setPainPoints] = useState([]);
  const [newInterest, setNewInterest] = useState("");
  const [newPainPoint, setNewPainPoint] = useState("");
  const [loading, setLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem("access_token");
        if (!token || !csrfToken) {
          console.error("Missing required tokens: Authorization or CSRF.");
          return;
        }

        const response = await fetch(
          `${API_URL}/users/target-audience-settings/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "X-CSRFToken": csrfToken,
            },
            credentials: "include",
          }
        );

        if (response.ok) {
          const userProfile = await response.json();
          setFormData({
            company_url: userProfile.company_url || "",
            target_audience_name: userProfile.target_audience_name || "",
            target_audience_gender: userProfile.target_audience_gender || "",
            target_audience_age_min:
              userProfile.target_audience_age_min || 10,
            target_audience_age_max:
              userProfile.target_audience_age_max || 65,
            target_audience_interests:
              userProfile.target_audience_interests || "",
            target_audience_pain_points:
              userProfile.target_audience_pain_points || "",
          });

          setInterests(
            userProfile.target_audience_interests
              ? userProfile.target_audience_interests.split(", ")
              : []
          );
          setPainPoints(
            userProfile.target_audience_pain_points
              ? userProfile.target_audience_pain_points.split(", ")
              : []
          );
        } else {
          console.error(
            "Failed to fetch user profile:",
            response.status,
            response.statusText
          );
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [csrfToken]);

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token || !csrfToken) {
        console.error("Missing required tokens: Authorization or CSRF.");
        return;
      }

      const updatedData = {
        ...formData,
        target_audience_interests: interests.join(", "),
        target_audience_pain_points: painPoints.join(", "),
      };

      const response = await fetch(
        `${API_URL}/users/target-audience-settings/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-CSRFToken": csrfToken,
          },
          credentials: "include",
          body: JSON.stringify(updatedData),
        }
      );

      if (response.ok) {
        alert("Target audience saved successfully.");
      } else {
        console.error(
          "Failed to save target audience:",
          response.status,
          response.statusText
        );
        alert("Failed to save target audience.");
      }
    } catch (error) {
      console.error("Error saving target audience:", error);
    }
  };

  const handleGenerateAI = async () => {
    if (!formData.company_url.trim()) {
      alert("Please provide a Company URL.");
      return;
    }

    setIsGenerating(true);
    try {
      const token = localStorage.getItem("access_token");
      if (!token || !csrfToken) {
        console.error("Missing required tokens: Authorization or CSRF.");
        setIsGenerating(false);
        return;
      }

      const interestResponse = await fetch(
        `${API_URL}/marketing_research/api/persona-interest/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-CSRFToken": csrfToken,
          },
          credentials: "include",
          body: JSON.stringify({ company_url: formData.company_url }),
        }
      );

      const painPointResponse = await fetch(
        `${API_URL}/marketing_research/api/persona-pain/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-CSRFToken": csrfToken,
          },
          credentials: "include",
          body: JSON.stringify({ company_url: formData.company_url }),
        }
      );

      if (interestResponse.ok && painPointResponse.ok) {
        const interestsData = await interestResponse.json();
        const painPointsData = await painPointResponse.json();

        setInterests(interestsData.options.map((item) => item.explanation));
        setPainPoints(painPointsData.options.map((item) => item.explanation));
      } else {
        console.error("Failed to generate data with AI.");
        alert("Failed to generate data with AI.");
      }
    } catch (error) {
      console.error("Error generating AI data:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="target-audience-container">
      <h2>Target Audience</h2>
      <div className="input-group-row">
        <div>
          <label>Target Audience Name</label>
          <input
            type="text"
            value={formData.target_audience_name}
            onChange={(e) =>
              setFormData({
                ...formData,
                target_audience_name: e.target.value,
              })
            }
            placeholder="Write your target audience name here"
          />
        </div>
        <div>
          <label>Target Audience Gender</label>
          <select
            value={formData.target_audience_gender}
            onChange={(e) =>
              setFormData({
                ...formData,
                target_audience_gender: e.target.value,
              })
            }
          >
            <option value="">Choose gender preference</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>

      <div className="slider-container">
        <p>Target Audience Age</p>
        <ReactSlider
          className="custom-slider"
          thumbClassName="custom-slider-thumb"
          trackClassName="custom-slider-track"
          value={[
            formData.target_audience_age_min,
            formData.target_audience_age_max,
          ]}
          min={10}
          max={65}
          step={1}
          onChange={(values) =>
            setFormData({
              ...formData,
              target_audience_age_min: values[0],
              target_audience_age_max: values[1],
            })
          }
        />
        <div className="slider-labels">
          <span>Min: {formData.target_audience_age_min}</span>
          <span>Max: {formData.target_audience_age_max}</span>
        </div>
      </div>

      <div>
        <h3>Interests</h3>
        <div className="options-container">
          {interests.map((interest, index) => (
            <div key={index} className="option-item">
              {interest}
              <button
                className="remove-button"
                onClick={() =>
                  setInterests(interests.filter((_, i) => i !== index))
                }
              >
                ✖
              </button>
            </div>
          ))}
        </div>
        <div className="input-group-row">
          <input
            type="text"
            className="signup-input"
            placeholder="Add new interest"
            value={newInterest}
            onChange={(e) => setNewInterest(e.target.value)}
          />
          <button
            className="add-button"
            onClick={() => {
              if (newInterest.trim()) {
                setInterests([...interests, newInterest.trim()]);
                setNewInterest("");
              }
            }}
          >
            Add
          </button>
        </div>
      </div>

      <div>
        <h3>Pain Points</h3>
        <div className="options-container">
          {painPoints.map((painPoint, index) => (
            <div key={index} className="option-item">
              {painPoint}
              <button
                className="remove-button"
                onClick={() =>
                  setPainPoints(painPoints.filter((_, i) => i !== index))
                }
              >
                ✖
              </button>
            </div>
          ))}
        </div>
        <div className="input-group-row">
          <input
            type="text"
            className="signup-input"
            placeholder="Add new pain point"
            value={newPainPoint}
            onChange={(e) => setNewPainPoint(e.target.value)}
          />
          <button
            className="add-button"
            onClick={() => {
              if (newPainPoint.trim()) {
                setPainPoints([...painPoints, newPainPoint.trim()]);
                setNewPainPoint("");
              }
            }}
          >
            Add
          </button>
        </div>
      </div>

      <div className="actions">
        <div>
          <label>Company URL</label>
          <input
            type="text"
            value={formData.company_url}
            onChange={(e) =>
              setFormData({ ...formData, company_url: e.target.value })
            }
            placeholder="Paste your specific URL here"
          />
        </div>
        <button
          className="generate-button"
          onClick={handleGenerateAI}
          disabled={isGenerating}
        >
          {isGenerating ? "Generating..." : "Generate with AI"}
        </button>
        <button className="save-button" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default TargetAudience;
