import React, { useState } from "react";
import { API_URL } from "../config";

const CreateProduct = ({ csrfToken, onProductAdded }) => {
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productUrl, setProductUrl] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleGenerateAI = async () => {
    if (!productUrl.trim()) {
      alert("Please provide a product URL before generating with AI.");
      return;
    }

    setIsGenerating(true);

    try {
      const token = localStorage.getItem("access_token");
      if (!token || !csrfToken) {
        console.error("Missing required tokens: Authorization or CSRF.");
        setIsGenerating(false);
        return;
      }

      const response = await fetch(`${API_URL}/marketing_research/api/brand-product/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
        },
        credentials: "include",
        body: JSON.stringify({ product_url: productUrl }),
      });

      if (response.ok) {
        const responseText = await response.text();
        setProductDescription(responseText);
      } else {
        console.error("Failed to generate product description:", response.statusText);
        alert("Failed to generate product description.");
      }
    } catch (error) {
      console.error("Error generating product description with AI:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSave = async () => {
    if (!productName.trim() || !productDescription.trim() || !productUrl.trim()) {
      alert("All fields are required!");
      return;
    }

    setIsSaving(true);

    try {
      const token = localStorage.getItem("access_token");
      if (!token || !csrfToken) {
        console.error("Missing required tokens: Authorization or CSRF.");
        setIsSaving(false);
        return;
      }

      const response = await fetch(`${API_URL}/users/create-product/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
        },
        credentials: "include",
        body: JSON.stringify({
          product_name: productName,
          product_description: productDescription,
          product_url: productUrl,
        }),
      });

      if (response.ok) {
        const savedProduct = await response.json();
        alert("Product saved successfully!");

        // Notify parent component about the new product
        onProductAdded(savedProduct);

        // Reset fields
        setProductName("");
        setProductDescription("");
        setProductUrl("");
      } else {
        console.error("Failed to save product:", response.statusText);
        alert("Failed to save product.");
      }
    } catch (error) {
      console.error("Error saving product:", error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="create-product-container">
      <h2>Create New Product</h2>
      <div className="form-group">
        <label>Product Name</label>
        <input
          type="text"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          placeholder="Enter product name"
        />
      </div>
      <div className="form-group">
        <label>Product URL</label>
        <input
          type="text"
          value={productUrl}
          onChange={(e) => setProductUrl(e.target.value)}
          placeholder="Enter product URL"
        />
      </div>
      <div className="form-group">
        <label>Product Description</label>
        <textarea
          value={productDescription}
          onChange={(e) => setProductDescription(e.target.value)}
          placeholder="Enter product description"
        />
      </div>
      <div className="button-group">
        <button
          id="generate-ai-button"
          onClick={handleGenerateAI}
          disabled={isGenerating}
          className="generate-button"
        >
          {isGenerating ? "Generating..." : "Generate with AI"}
        </button>
        <button
          id="save-product-button"
          onClick={handleSave}
          disabled={isSaving}
          className="save-button"
        >
          {isSaving ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
};

export default CreateProduct;
