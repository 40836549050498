import React, { useState, useEffect } from 'react';
import { API_URL } from "../config";
import '../styles/BrandStrategy.css'; // Import CSS styles

const BrandStrategy = ({ csrfToken }) => {
  const [brandStrategy, setBrandStrategy] = useState({
    company_url: '',
    brand_usp: '',
    brand_differentiation: '',
    brand_value_proposition_statement: '',
  });

  const [loading, setLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    const fetchBrandStrategy = async () => {
      try {
        const token = localStorage.getItem('access_token');
        if (!token || !csrfToken) {
          console.error('Missing required tokens: Authorization or CSRF.');
          return;
        }

        const response = await fetch(`${API_URL}/users/update-brand-strategy/`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-CSRFToken': csrfToken,
          },
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setBrandStrategy(data);
        } else {
          console.error('Failed to fetch brand strategy:', response.status, response.statusText);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching brand strategy:', error);
      }
    };

    fetchBrandStrategy();
  }, [csrfToken]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBrandStrategy({
      ...brandStrategy,
      [name]: value,
    });
  };

  const handleGenerateWithAI = async (field, apiUrl) => {
    if (!brandStrategy.company_url.trim()) {
      alert('Please provide a Company URL.');
      return;
    }

    setIsGenerating(true);
    try {
      const token = localStorage.getItem('access_token');
      if (!token || !csrfToken) {
        console.error('Missing required tokens: Authorization or CSRF.');
        setIsGenerating(false);
        return;
      }

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-CSRFToken': csrfToken,
        },
        credentials: 'include',
        body: JSON.stringify({ company_url: brandStrategy.company_url }),
      });

      if (response.ok) {
        const generatedText = await response.text();
        setBrandStrategy((prevState) => ({
          ...prevState,
          [field]: generatedText,
        }));
      } else {
        console.error(`Failed to generate ${field}:`, response.status, response.statusText);
      }
    } catch (error) {
      console.error(`Error generating ${field}:`, error);
    } finally {
      setIsGenerating(false);
    }
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const token = localStorage.getItem('access_token');
    if (!token || !csrfToken) {
      console.error('Missing required tokens: Authorization or CSRF.');
      return;
    }

    const response = await fetch(`${API_URL}/users/update-brand-strategy/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-CSRFToken': csrfToken,
      },
      credentials: 'include',
      body: JSON.stringify(brandStrategy), // Ensure entire state is sent
    });

    if (response.ok) {
      const updatedData = await response.json();
      setBrandStrategy(updatedData); // Update local state with backend response
      alert('Brand Strategy updated successfully!');
    } else {
      const errorData = await response.json();
      console.error('Failed to update brand strategy:', errorData);
      alert('Failed to update Brand Strategy. Check console for details.');
    }
  } catch (error) {
    console.error('Error updating brand strategy:', error);
    alert('An error occurred while saving. Check console for details.');
  }
};


  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="brand-strategy-container">
      <form onSubmit={handleSubmit} className="brand-strategy-form">
        <div className="form-group">
          <label>Brand Home Page URL</label>
          <input
            type="text"
            name="company_url"
            value={brandStrategy.company_url || ''}
            onChange={handleChange}
            placeholder="https://www.example.com"
          />
        </div>
        <div className="form-group">
          <label>Brand USP</label>
          <div className="input-with-button">
            <textarea
              name="brand_usp"
              value={brandStrategy.brand_usp || ''}
              onChange={handleChange}
              placeholder="Write your Unique Selling Proposition here"
            />
            <button
              type="button"
              className="generate-button"
              onClick={() => handleGenerateWithAI('brand_usp', `${API_URL}/marketing_research/api/brand-usp/`)}
              disabled={isGenerating}
            >
              {isGenerating ? 'Generating...' : 'Generate with AI'}
            </button>
          </div>
        </div>
        <div className="form-group">
          <label>Brand Differentiation</label>
          <div className="input-with-button">
            <textarea
              name="brand_differentiation"
              value={brandStrategy.brand_differentiation || ''}
              onChange={handleChange}
              placeholder="Write your brand differentiation here"
            />
            <button
              type="button"
              className="generate-button"
              onClick={() =>
                handleGenerateWithAI(
                  'brand_differentiation',
                  `${API_URL}/marketing_research/api/brand-differentiation/`
                )
              }
              disabled={isGenerating}
            >
              {isGenerating ? 'Generating...' : 'Generate with AI'}
            </button>
          </div>
        </div>
        <div className="form-group">
          <label>Brand Value Proposition Statement</label>
          <div className="input-with-button">
            <textarea
              name="brand_value_proposition_statement"
              value={brandStrategy.brand_value_proposition_statement || ''}
              onChange={handleChange}
              placeholder="Write your value proposition statement here"
            />
            <button
              type="button"
              className="generate-button"
              onClick={() =>
                handleGenerateWithAI(
                  'brand_value_proposition_statement',
                  `${API_URL}/marketing_research/api/brand-value/`
                )
              }
              disabled={isGenerating}
            >
              {isGenerating ? 'Generating...' : 'Generate with AI'}
            </button>
          </div>
        </div>
        <button type="submit" className="save-button">Save</button>
      </form>
    </div>
  );
};

export default BrandStrategy;
