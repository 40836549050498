import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import '../styles/ProfileSettings.css';

const ProfileSettings = ({ data }) => {
  const [formData, setFormData] = useState({
    username: data?.username || '',
    email: data?.email || '',
    first_name: data?.first_name || '',
    last_name: data?.last_name || '',
    job_level: data?.job_level || '',
    role: data?.role || '',
  });
  const [passwordData, setPasswordData] = useState({
    new_password: '',
    confirm_password: '',
  });
  const [saveMessage, setSaveMessage] = useState(null);
  const [passwordMessage, setPasswordMessage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value });
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('access_token');
      await axios.put(
        `${API_URL}/users/settings/`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setSaveMessage('Profile updated successfully!');
    } catch (error) {
      setSaveMessage('Failed to save changes. Please try again.');
    }
  };

  const handlePasswordSubmit = async () => {
    if (passwordData.new_password !== passwordData.confirm_password) {
      setPasswordMessage('Passwords do not match.');
      return;
    }

    try {
      const token = localStorage.getItem('access_token');
      await axios.patch(
        `${API_URL}/users/settings/`,
        { new_password: passwordData.new_password },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setPasswordMessage('Password updated successfully!');
      setPasswordData({ new_password: '', confirm_password: '' });
    } catch (error) {
      setPasswordMessage('Failed to update password. Please try again.');
    }
  };

  return (
    <div className="profile-settings-container">
      <div className="block-container">
        <h2 className="profile-settings-header">Profile Edit</h2>
        <div className="flex-container">
          <div className="profile-field">
            <label htmlFor="first_name">First Name</label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={formData.first_name}
              onChange={handleInputChange}
            />
          </div>
          <div className="profile-field">
            <label htmlFor="last_name">Last Name</label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={formData.last_name}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="flex-container">
          <div className="profile-field">
            <label htmlFor="job_level">Job Level</label>
            <select
              id="job_level"
              name="job_level"
              value={formData.job_level}
              onChange={handleInputChange}
            >
              <option value="">Select your professional level</option>
              <option value="C-Level">C-Level</option>
              <option value="VP or Executive">VP or Executive</option>
              <option value="Director">Director</option>
              <option value="Team Leader">Team Leader</option>
              <option value="Manager">Manager</option>
              <option value="Senior">Senior</option>
              <option value="Junior">Junior</option>
              <option value="Owner/Founder">Owner/Founder</option>
              <option value="Freelancer">Freelancer</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="profile-field">
            <label htmlFor="role">Role</label>
            <select
              id="role"
              name="role"
              value={formData.role}
              onChange={handleInputChange}
            >
              <option value="">Select your job role</option>
              <option value="Marketing">Marketing</option>
              <option value="Design">Design</option>
              <option value="Engineering">Engineering</option>
              <option value="Finance">Finance</option>
              <option value="Product">Product</option>
              <option value="Sales">Sales</option>
              <option value="Customer Support">Customer Support</option>
              <option value="Customer Success">Customer Success</option>
              <option value="HR">HR</option>
              <option value="Project Management">Project Management</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <button className="save-button" onClick={handleSave}>
          Save Changes
        </button>
        {saveMessage && <p className="save-message">{saveMessage}</p>}
      </div>

      <div className="block-container">
        <h2 className="profile-settings-header">Change Password</h2>
        <div className="flex-container">
          <div className="profile-field">
            <label htmlFor="new_password">New Password</label>
            <input
              type="password"
              id="new_password"
              name="new_password"
              value={passwordData.new_password}
              onChange={handlePasswordChange}
            />
          </div>
          <div className="profile-field">
            <label htmlFor="confirm_password">Confirm Password</label>
            <input
              type="password"
              id="confirm_password"
              name="confirm_password"
              value={passwordData.confirm_password}
              onChange={handlePasswordChange}
            />
          </div>
        </div>
        <button className="save-button" onClick={handlePasswordSubmit}>
          Change Password
        </button>
        {passwordMessage && <p className="save-message">{passwordMessage}</p>}
      </div>
    </div>
  );
};

export default ProfileSettings;
