import React, { useState } from "react";
import { API_URL } from "../config";

const CreateKnowledge = ({ csrfToken, onKnowledgeAdded }) => {
  const [knowledgeName, setKnowledgeName] = useState("");
  const [knowledgeDescription, setKnowledgeDescription] = useState("");
  const [knowledgeUrl, setKnowledgeUrl] = useState("");
  const [knowledgeTag, setKnowledgeTag] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleGenerateAI = async () => {
    if (!knowledgeUrl.trim()) {
      alert("Please provide a knowledge URL before generating with AI.");
      return;
    }

    setIsGenerating(true);

    try {
      const token = localStorage.getItem("access_token");
      if (!token || !csrfToken) {
        console.error("Missing required tokens: Authorization or CSRF.");
        setIsGenerating(false);
        return;
      }

      const response = await fetch(`${API_URL}/marketing_research/api/brand-knowledge/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
        },
        credentials: "include",
        body: JSON.stringify({ knowledge_url: knowledgeUrl }),
      });

      if (response.ok) {
        const responseText = await response.text();
        setKnowledgeDescription(responseText);
      } else {
        console.error("Failed to generate knowledge description:", response.statusText);
        alert("Failed to generate knowledge description.");
      }
    } catch (error) {
      console.error("Error generating knowledge description with AI:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSave = async () => {
    if (!knowledgeName.trim() || !knowledgeDescription.trim() || !knowledgeUrl.trim() || !knowledgeTag.trim()) {
      alert("All fields are required!");
      return;
    }

    setIsSaving(true);

    try {
      const token = localStorage.getItem("access_token");
      if (!token || !csrfToken) {
        console.error("Missing required tokens: Authorization or CSRF.");
        setIsSaving(false);
        return;
      }

      const response = await fetch(`${API_URL}/users/create-knowledge/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
        },
        credentials: "include",
        body: JSON.stringify({
          knowledge_name: knowledgeName,
          knowledge_description: knowledgeDescription,
          knowledge_url: knowledgeUrl,
          knowledge_tag: knowledgeTag,
        }),
      });

      if (response.ok) {
        const savedKnowledge = await response.json();
        alert("Knowledge saved successfully!");
        console.log("Saved knowledge:", savedKnowledge);

        // Reset fields
        setKnowledgeName("");
        setKnowledgeDescription("");
        setKnowledgeUrl("");
        setKnowledgeTag("");

        // Notify parent component
        onKnowledgeAdded(savedKnowledge);
      } else {
        console.error("Failed to save knowledge:", response.statusText);
        alert("Failed to save knowledge.");
      }
    } catch (error) {
      console.error("Error saving knowledge:", error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="create-knowledge-container">
      <h2>Create New Knowledge</h2>
      <div className="form-group">
        <label>Knowledge Name</label>
        <input
          type="text"
          value={knowledgeName}
          onChange={(e) => setKnowledgeName(e.target.value)}
          placeholder="Enter knowledge name"
        />
      </div>
      <div className="form-group">
        <label>Knowledge URL</label>
        <input
          type="text"
          value={knowledgeUrl}
          onChange={(e) => setKnowledgeUrl(e.target.value)}
          placeholder="Enter knowledge URL"
        />
      </div>
      <div className="form-group">
        <label>Knowledge Description</label>
        <textarea
          value={knowledgeDescription}
          onChange={(e) => setKnowledgeDescription(e.target.value)}
          placeholder="Enter knowledge description"
        />
      </div>
      <div className="form-group">
        <label>Knowledge Tag</label>
        <input
          type="text"
          value={knowledgeTag}
          onChange={(e) => setKnowledgeTag(e.target.value)}
          placeholder="Enter knowledge tag"
        />
      </div>
      <div className="button-group">
        <button
          id="generate-knowledge-button"
          onClick={handleGenerateAI}
          disabled={isGenerating}
        >
          {isGenerating ? "Generating..." : "Generate with AI"}
        </button>
        <button
          id="save-knowledge-button"
          onClick={handleSave}
          disabled={isSaving}
        >
          {isSaving ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
};

export default CreateKnowledge;
