import React, { useState } from "react";
import { API_URL } from "../config";

const CreatePerforming = ({ csrfToken, onPerformingAdded }) => {
  const [performingName, setPerformingName] = useState("");
  const [performingDescription, setPerformingDescription] = useState("");
  const [performingTag, setPerformingTag] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    if (!performingName.trim() || !performingDescription.trim() || !performingTag.trim()) {
      alert("All fields are required!");
      return;
    }

    setIsSaving(true);

    try {
      const token = localStorage.getItem("access_token");
      if (!token || !csrfToken) {
        console.error("Missing required tokens: Authorization or CSRF.");
        setIsSaving(false);
        return;
      }

      const response = await fetch(`${API_URL}/users/create-performing/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
        },
        credentials: "include",
        body: JSON.stringify({
          performing_name: performingName,
          performing_description: performingDescription,
          performing_tag: performingTag,
        }),
      });

      if (response.ok) {
        const savedPerforming = await response.json();
        alert("Top Performing saved successfully!");
        console.log("Saved performing:", savedPerforming);

        // Reset fields
        setPerformingName("");
        setPerformingDescription("");
        setPerformingTag("");

        // Notify parent component
        onPerformingAdded(savedPerforming);
      } else {
        console.error("Failed to save performing:", response.statusText);
        alert("Failed to save performing.");
      }
    } catch (error) {
      console.error("Error saving performing:", error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="create-performing-container">
      <h2>Create New Performing</h2>
      <div className="form-group">
        <label htmlFor="performing-name">Performing Name</label>
        <input
          id="performing-name"
          type="text"
          value={performingName}
          onChange={(e) => setPerformingName(e.target.value)}
          placeholder="Enter performing name"
        />
      </div>
      <div className="form-group">
        <label htmlFor="performing-description">Performing Description</label>
        <textarea
          id="performing-description"
          value={performingDescription}
          onChange={(e) => setPerformingDescription(e.target.value)}
          placeholder="Enter performing description"
        />
      </div>
      <div className="form-group">
        <label htmlFor="performing-tag">Performing Tag</label>
        <input
          id="performing-tag"
          type="text"
          value={performingTag}
          onChange={(e) => setPerformingTag(e.target.value)}
          placeholder="Enter performing tag"
        />
      </div>
      <div className="button-group">
        <button
          id="save-performing-button"
          onClick={handleSave}
          disabled={isSaving}
        >
          {isSaving ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
};

export default CreatePerforming;
