import React, { useState, useEffect, useCallback, useRef } from "react";
import { API_URL } from "../config";
import CreateProduct from "./CreateProduct";
import "../styles/ProductList.css";

const ProductList = ({ csrfToken }) => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const [loading, setLoading] = useState(true);

  // Reference for the Create Product form
  const createProductRef = useRef(null);

  // Fetch Products Function
  const fetchProducts = useCallback(async () => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token || !csrfToken) {
        console.error("Missing required tokens: Authorization or CSRF.");
        return;
      }

      const response = await fetch(`${API_URL}/users/my-products/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
        },
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        setProducts(data);
        setFilteredProducts(data); // Set initial filtered products
      } else {
        console.error(
          "Failed to fetch products:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  }, [csrfToken]);

  // Load Products on Mount
  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  // Toggle visibility of CreateProduct form
  const handleCreateProduct = () => {
    setShowCreateProduct((prevState) => !prevState);
    if (!showCreateProduct && createProductRef.current) {
      createProductRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Handle Adding a New Product
  const handleProductAdded = (newProduct) => {
    setProducts((prevProducts) => [...prevProducts, newProduct]); // Update state immediately
    setFilteredProducts((prevProducts) => [...prevProducts, newProduct]); // Update filtered list
    setShowCreateProduct(false);
  };

  // Handle Search Input
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = products.filter((product) =>
      product.product_name.toLowerCase().includes(term)
    );
    setFilteredProducts(filtered);
  };

  // Handle Delete Product
  const handleDeleteProduct = async (productId) => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token || !csrfToken) {
        console.error("Missing required tokens: Authorization or CSRF.");
        return;
      }

      const response = await fetch(`${API_URL}/users/delete-product/${productId}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
        },
        credentials: "include",
      });

      if (response.ok) {
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.id !== productId)
        );
        setFilteredProducts((prevProducts) =>
          prevProducts.filter((product) => product.id !== productId)
        );
        alert("Product deleted successfully.");
      } else {
        console.error(
          "Failed to delete product:",
          response.status,
          response.statusText
        );
        alert("Failed to delete product.");
      }
    } catch (error) {
      console.error("Error deleting product:", error);
      alert("An error occurred while deleting the product.");
    }
  };

  if (loading) {
    return <p>Loading products...</p>;
  }

  return (
    <div className="product-list-container">
      <h2>My Products</h2>

      {/* Search Input */}
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by product name..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
      </div>

      {/* Product Table */}
      <div className="product-table">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Product Name</th>
              <th>Description</th>
              <th>URL</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product, index) => (
                <tr key={product.id}>
                  <td>{index + 1}</td>
                  <td>{product.product_name}</td>
                  <td>{product.product_description}</td>
                  <td>
                    <a
                      href={product.product_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visit
                    </a>
                  </td>
                  <td>
                    <button
                      className="delete-button"
                      onClick={() => handleDeleteProduct(product.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="no-products-message">
                  No products found. Start by adding a new one!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Add Product Button */}
      <button
        id="add-product-button"
        className="add-product-button"
        onClick={handleCreateProduct}
      >
        {showCreateProduct ? "Cancel" : "Add New Product"}
      </button>

      {/* Create Product Form */}
      {showCreateProduct && (
        <div ref={createProductRef}>
          <CreateProduct
            csrfToken={csrfToken}
            onProductAdded={(newProduct) => {
              handleProductAdded(newProduct);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProductList;
