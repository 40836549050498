import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/CreateAnalytics.css';
import { API_URL } from '../config';

const CreateAnalytics = ({ onFetchDataComplete, csrfToken }) => {
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [dateRange, setDateRange] = useState('custom');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const fetchAnalyticsProperties = async () => {
      try {
        const response = await axios.get(`${API_URL}/fetch_analytics/`, {
          withCredentials: true,
        });
        const propertyDisplayNames = response.data.map(property => ({
          id: property.name,
          displayName: property.displayName,
        }));
        setProperties(propertyDisplayNames);
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Error fetching analytics properties:', error);
        setError('Please log in to access Google Analytics data.');
        setIsAuthenticated(false);
      }
    };

    fetchAnalyticsProperties();
  }, []);

  const handleLoginRequest = () => {
    window.location.href = `${API_URL}/login`
  };
  const handleLogout = () => {
  window.location.href = `${API_URL}/logout/`;
};


  const handleDateRangeChange = (range) => {
    setDateRange(range);
    const today = new Date();

    if (range === 'last7days') {
      const last7Days = new Date();
      last7Days.setDate(today.getDate() - 7);
      setStartDate(last7Days.toISOString().split('T')[0]);
      setEndDate(today.toISOString().split('T')[0]);
    } else if (range === 'last28days') {
      const last28Days = new Date();
      last28Days.setDate(today.getDate() - 28);
      setStartDate(last28Days.toISOString().split('T')[0]);
      setEndDate(today.toISOString().split('T')[0]);
    } else if (range === 'last6months') {
      const last6Months = new Date();
      last6Months.setMonth(today.getMonth() - 6);
      setStartDate(last6Months.toISOString().split('T')[0]);
      setEndDate(today.toISOString().split('T')[0]);
    } else {
      setStartDate('');
      setEndDate('');
    }
  };

  const handleFetchData = async () => {
    if (!selectedProperty || !startDate || !endDate) {
      setError('Please select a property and enter start and end dates.');
      return;
    }

    setLoading(true);

    const payload = {
      selected_property_id: selectedProperty,
      start_date: startDate,
      end_date: endDate,
    };

    try {
      const countryResponse = await axios.post(
        `${API_URL}/select_country_traffic/`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
        }
      );

      const returningUsersResponse = await axios.post(
        `${API_URL}/traffic-by-city-returning-users/`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
        }
      );

      const landingPageResponse = await axios.post(
        `${API_URL}/traffic-by-landing-page/`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
        }
      );

      const landingPageFilterResponse = await axios.post(
        `${API_URL}/traffic-by-landing-page-sessions-filter/`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
        }
      );

      const landingPageBounceRateResponse = await axios.post(
        `${API_URL}/traffic-by-landing-page-bounce-rate/`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
        }
      );

      const landingPageTitleResponse = await axios.post(
        `${API_URL}/traffic-by-page-title/`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
        }
      );

      const landingSourceMediumResponse = await axios.post(
        `${API_URL}/traffic-by-source-medium/`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
        }
      );

      const landingDefaultChannelResponse = await axios.post(
        `${API_URL}/traffic-by-default-channel-group/`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
        }
      );

        const GenderTrafficResponse = await axios.post(
        `${API_URL}/traffic-by-gender/`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
        }
      );

        const AgeTrafficResponse = await axios.post(
        `${API_URL}/traffic-by-age/`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
        }
      );
        const InterestTrafficResponse = await axios.post(
        `${API_URL}/traffic-by-interest/`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
        }
      );
const token = localStorage.getItem('access_token');

const saveGenderAnalyticsResponse = await axios.post(
  `${API_URL}/save-latest-gender-analytics/`,
  {},
  {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken,
      'Authorization': `Bearer ${token}`, // Include the Authorization header
    },
  }
);

const saveAgeAnalyticsResponse = await axios.post(
  `${API_URL}/save-latest-age-analytics/`,
  {},
  {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken,
      'Authorization': `Bearer ${token}`, // Include the Authorization header
    },
  }
);

const saveInterestAnalyticsResponse = await axios.post(
  `${API_URL}/save-latest-interest-analytics/`,
  {},
  {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken,
      'Authorization': `Bearer ${token}`, // Include the Authorization header
    },
  }
);

const savePageTitleResponse = await axios.post(
  `${API_URL}/save-latest-page-title/`,
  {},
  {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken,
      'Authorization': `Bearer ${token}`, // Include the Authorization header
    },
  }
);



  console.log('Saved Gender Analytics Response:', saveGenderAnalyticsResponse.data);
  console.log('Saved Age Analytics Response:', saveAgeAnalyticsResponse.data);
  console.log('Saved Interest Analytics Response:', saveInterestAnalyticsResponse.data);
  console.log('Saved Page title Response:', savePageTitleResponse.data);

      setError(null);
      onFetchDataComplete({
        countryData: countryResponse.data,
        returningUsersData: returningUsersResponse.data,
        landingPageData: landingPageResponse.data,
        landingPageFilterData: landingPageFilterResponse.data,
        landingPageBounceRateData: landingPageBounceRateResponse.data,
        landingPageTitleData: landingPageTitleResponse.data,
        landingSourceMediumData: landingSourceMediumResponse.data,
        landingDefaultChannelData: landingDefaultChannelResponse.data,
        GenderTrafficData: GenderTrafficResponse.data,
        AgeTrafficData: AgeTrafficResponse.data,
        InterestTrafficData: InterestTrafficResponse.data
      });
    } catch (error) {
      console.error('Error fetching analytics data:', error);
      setError('Failed to fetch analytics data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

return (
    <div className="analytics-container">
      <h1 className="analytics-title">Google Analytics</h1>

      <div className="button-container">
        {!isAuthenticated ? (
            <button className="analytics-button" onClick={handleLoginRequest}>
              Login with Google
            </button>
        ) : (
            <button className="analytics-button logout-button" onClick={handleLogout}>
              Logout
            </button>
        )}
      </div>

      {error && <p className="analytics-error">{error}</p>}
      {!properties.length ? (
          <>
            {error ? (
                <p className="analytics-loading"></p>
            ) : (
                <p className="analytics-loading">Loading properties...</p>
            )}
          </>
      ) : (
          <div className="analytics-form">
            <label className="analytics-label" htmlFor="properties">
              Select a Google Analytics Property:
            </label>
            <select
                className="analytics-select"
                id="properties"
                value={selectedProperty}
                onChange={(e) => setSelectedProperty(e.target.value)}
            >
              <option value="">-- Select a Property --</option>
              {properties.map((property) => (
                  <option key={property.id} value={property.id}>
                    {property.displayName}
                  </option>
              ))}
            </select>

            <div className="analytics-date-range">
              <label className="analytics-label" htmlFor="date-range">Date Range:</label>
              <select
                  className="analytics-select"
                  id="date-range"
                  value={dateRange}
                  onChange={(e) => handleDateRangeChange(e.target.value)}
              >
                <option value="custom">Custom</option>
                <option value="last7days">Last 7 Days</option>
                <option value="last28days">Last 28 Days</option>
                <option value="last6months">Last 6 Months</option>
              </select>
            </div>

            {dateRange === 'custom' && (
                <>
                  <div className="analytics-date-input">
                    <label className="analytics-label" htmlFor="start-date">Start Date:</label>
                    <input
                        type="date"
                        id="start-date"
                        className="analytics-date-picker"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>

                  <div className="analytics-date-input">
                    <label className="analytics-label" htmlFor="end-date">End Date:</label>
                    <input
                        type="date"
                        id="end-date"
                        className="analytics-date-picker"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </>
            )}

            <button
                className="analytics-button"
                onClick={handleFetchData}
                disabled={loading}
            >
              {loading ? 'Fetching...' : 'Fetch Analytics Data'}
            </button>

            {loading && <div className="analytics-spinner"></div>}
          </div>
      )}
    </div>
);

};

export default CreateAnalytics;
