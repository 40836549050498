import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import ReactSlider from "react-slider";
import '../styles/TargetAudience.css';

const TargetAudienceSettings = ({ data }) => {
  const [targetAudienceData, setTargetAudienceData] = useState({
    target_audience_name: data?.target_audience_name || "",
    target_audience_gender: data?.target_audience_gender || "",
    target_audience_age_min: data?.target_audience_age_min || 18,
    target_audience_age_max: data?.target_audience_age_max || 65,
    target_audience_interests: data?.target_audience_interests
      ? data.target_audience_interests.split(", ")
      : [],
    target_audience_pain_points: data?.target_audience_pain_points
      ? data.target_audience_pain_points.split(", ")
      : [],
  });

  const [newInterest, setNewInterest] = useState("");
  const [newPainPoint, setNewPainPoint] = useState("");
  const [saveMessage, setSaveMessage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTargetAudienceData({ ...targetAudienceData, [name]: value });
  };

  const handleAgeChange = (values) => {
    setTargetAudienceData((prev) => ({
      ...prev,
      target_audience_age_min: values[0],
      target_audience_age_max: values[1],
    }));
  };

  const addInterest = () => {
    if (newInterest.trim() && !targetAudienceData.target_audience_interests.includes(newInterest)) {
      setTargetAudienceData((prev) => ({
        ...prev,
        target_audience_interests: [...prev.target_audience_interests, newInterest],
      }));
      setNewInterest("");
    }
  };

  const removeInterest = (interest) => {
    setTargetAudienceData((prev) => ({
      ...prev,
      target_audience_interests: prev.target_audience_interests.filter((item) => item !== interest),
    }));
  };

  const addPainPoint = () => {
    if (newPainPoint.trim() && !targetAudienceData.target_audience_pain_points.includes(newPainPoint)) {
      setTargetAudienceData((prev) => ({
        ...prev,
        target_audience_pain_points: [...prev.target_audience_pain_points, newPainPoint],
      }));
      setNewPainPoint("");
    }
  };

  const removePainPoint = (painPoint) => {
    setTargetAudienceData((prev) => ({
      ...prev,
      target_audience_pain_points: prev.target_audience_pain_points.filter((item) => item !== painPoint),
    }));
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const payload = {
        ...targetAudienceData,
        target_audience_interests: targetAudienceData.target_audience_interests.join(", "),
        target_audience_pain_points: targetAudienceData.target_audience_pain_points.join(", "),
      };

      await axios.put(`${API_URL}/users/target-audience-settings/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setSaveMessage("Target audience information updated successfully!");
    } catch (error) {
      setSaveMessage("Failed to save changes. Please try again.");
    }
  };

  return (
    <div className="profile-settings-container">
      <h2 className="profile-settings-header">Target Audience Edit</h2>

      {/* General Information */}
      <div className="profile-settings-section">
        <div className="profile-field">
          <label htmlFor="target_audience_name">Audience Name</label>
          <input
            type="text"
            id="target_audience_name"
            name="target_audience_name"
            value={targetAudienceData.target_audience_name}
            onChange={handleInputChange}
          />
        </div>
        <div className="profile-field">
          <label htmlFor="target_audience_gender">Gender</label>
          <select
            id="target_audience_gender"
            name="target_audience_gender"
            value={targetAudienceData.target_audience_gender}
            onChange={handleInputChange}
          >
            <option value="">Select gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>

      {/* Age Range */}
      <div className="profile-settings-section">
        <h3>Age Range</h3>
        <ReactSlider
          className="custom-slider"
          thumbClassName="custom-slider-thumb"
          trackClassName="custom-slider-track"
          value={[
            targetAudienceData.target_audience_age_min,
            targetAudienceData.target_audience_age_max,
          ]}
          min={18}
          max={100}
          step={1}
          onChange={handleAgeChange}
        />
        <div className="slider-labels">
          <span>Min: {targetAudienceData.target_audience_age_min}</span>
          <span>Max: {targetAudienceData.target_audience_age_max}</span>
        </div>
      </div>

      {/* Interests */}
      <div className="profile-settings-section">
        <h3>Interests</h3>
        <div className="options-container">
          {targetAudienceData.target_audience_interests.map((interest, index) => (
            <div key={index} className="option-item">
              {interest}
              <button
                className="remove-button"
                onClick={() => removeInterest(interest)}
              >
                ✖
              </button>
            </div>
          ))}
        </div>
        <div className="profile-field">
          <input
            type="text"
            placeholder="Add interest"
            value={newInterest}
            onChange={(e) => setNewInterest(e.target.value)}
          />
          <button className="add-button" onClick={addInterest}>
            Add
          </button>
        </div>
      </div>

      {/* Pain Points */}
      <div className="profile-settings-section">
        <h3>Pain Points</h3>
        <div className="options-container">
          {targetAudienceData.target_audience_pain_points.map((painPoint, index) => (
            <div key={index} className="option-item">
              {painPoint}
              <button
                className="remove-button"
                onClick={() => removePainPoint(painPoint)}
              >
                ✖
              </button>
            </div>
          ))}
        </div>
        <div className="profile-field">
          <input
            type="text"
            placeholder="Add pain point"
            value={newPainPoint}
            onChange={(e) => setNewPainPoint(e.target.value)}
          />
          <button className="add-button" onClick={addPainPoint}>
            Add
          </button>
        </div>
      </div>

      {/* Save Button */}
      <button className="save-button" onClick={handleSave}>
        Save Changes
      </button>
      {saveMessage && <p className="save-message">{saveMessage}</p>}
    </div>
  );
};

export default TargetAudienceSettings;